import React from 'react';
import { InputDialog } from 'wix-ui-tpa';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { connect } from '../../../../common/components/runtime-context';
import { getRegionalSettings, getUpmModalSubscription, isUpmModalOpen } from '../../selectors';
import { RootState } from '../../state';
import { PaymentsWidget, PaymentsWidgetAPI } from '@wix/cashier-payments-widget/lazy';
import { IActionCreators } from '../../../../types/internal-types';
import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { classes, st } from './UpmModal.st.css';
import classNames from 'classnames';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;
interface Props {
  subscription: Subscription;
}
export const UpmModal = ({
  isOpen,
  subscription,
  actionCreators,
  language,
  IdentityParams,
  visitorId,
}: RuntimeProps & Props) => {
  const { isMobile } = useEnvironment();

  const isPaymentsWidgetFullyMounted = React.useRef(false);
  const paymentsWidgetApi = React.useRef<PaymentsWidgetAPI | undefined>();
  const { t } = useTranslation();
  const { msid, instance, siteOwnerId, sessionId } = IdentityParams;
  console.log('mounted:', isPaymentsWidgetFullyMounted);

  const upmModalSubmit = async () => {
    if (isPaymentsWidgetFullyMounted.current) {
      const savedPaymentMethodResponse = await paymentsWidgetApi.current?.getSavedPaymentMethod().catch(() => {});
      if (savedPaymentMethodResponse?.paymentAgreementId) {
        const paymentMethodId = savedPaymentMethodResponse?.paymentAgreementId || '';
        actionCreators.submitUpm({
          subscriptionId: subscription.id!,
          paymentMethodId,
        });
      }
    }
  };

  return (
    <div className={classNames(st(classes.root, { desktop: !isMobile }))}>
      <InputDialog
        title="My Dialog Title"
        subtitle="Subtitle"
        data-hook="upm-modal"
        isOpen={isOpen}
        onClose={() => actionCreators.closeUpmModal()}
        primaryButton={{
          text: 'Update',
          onClick: upmModalSubmit,
        }}
        secondaryButton={{
          text: 'Cancel', // TODO:add content
          onClick: () => actionCreators.closeUpmModal(),
        }}
      >
        <div className={classes.paymentsWidgetContainer} data-hook="payments-widget-container">
          <PaymentsWidget
            configuration={{
              locale: language,
              appId: IdentityParams.appDefinitionId,
              appInstanceId: IdentityParams.appInstanceId,
              appInstance: instance,
              amount: '0',
              currency: subscription.billingSettings!.currency!,
              msid: msid || '',
              siteOwnerId,
              visitorId,
              viewMode: 'Site',
              isSignedInUser: true,
              sessionId,
            }}
            autoSelectedPaymentAgreementId={subscription.billingSettings!.paymentMethod?.id}
            externalSubmitButton // ability to show our own button
            onCrash={() => {}} // Payments will show error message,
            onError={() => {}}
            onApiInit={(api) => {
              paymentsWidgetApi.current = api;
              isPaymentsWidgetFullyMounted.current = true;
            }} // wait for an API
            isSaveCCEnabled={true}
          />
        </div>
      </InputDialog>
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => {
  return {
    isOpen: isUpmModalOpen(state),
    subscription: getUpmModalSubscription(state),
    actionCreators,
    language: getRegionalSettings(state),
    IdentityParams: state.IdentityParams,
    visitorId: state.user?.id,
  };
};

export default connect(mapRuntimeToProps)(UpmModal);
